<script>
export default {
  name: 'LadExperiences',
  components: {
    LadCard: () =>
      import(/* webpackChunkName: "lad-card" */ '@/components/card/index'),
    LadExperienceSwiperLoader: () =>
      import(
        /* webpackChunkName: "lad-experience-swiper-loader" */ '@/components/css-skeleton/experience-swiper-loader'
      ),
  },
  data() {
    return {
      base: 'components.experience',
      products: [],
      current: 0,
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: () => {
            const swiper = this.$refs.swiperExperiences.$swiper

            if (swiper) {
              this.current = swiper.activeIndex % this.products.length
            }
          },
        },
      },
    }
  },
  methods: {
    setData(data) {
      const list = []

      data.forEach((item) => {
        list.push({
          uuid: item.uuid,
          type: item.type ? item.type : 'experience',
          title: item.name,
          description: item.headline,
          days: item.duration,
          is_lad: item.is_lad || true,
          total_amount: parseFloat(item.sales_plan.total_amount),
          reviews: {
            count: parseFloat(item.reviews.count),
            value: parseFloat(item.reviews.value),
          },
          starting_point: item.starting_point,
          permalink: item.permalink,
          promotions: item.promotions,
          media: {
            type: 'image',
            data: {
              url: item.cover.url,
              srcset: item.cover.srcset,
              uuid: item.cover.uuid,
            },
          },
          country: {
            name: item.country.name,
          },
        })
      })

      this.LadNextTick(() => (this.products = list), 'LadExperiences')
    },
  },
}
</script>

<style lang="scss">
.lad-experiences {
  background-color: #ebeef2;

  .swiper-wrapper {
    .swiper-slide {
      width: 220px;
    }
  }

  .swiper-pagination {
    position: relative;
    margin-top: 20px;
    bottom: inherit;
  }

  .section-header {
    padding: 15px 0;
  }

  .section-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .section-description {
    text-align: center;
    text-transform: uppercase;
  }
}
</style>
