<template>
  <div class="lad-experiences">
    <el-container>
      <el-main>
        <div class="section-header">
          <h2 class="section-title">{{ $t(`${base}.lasted.title`) }}</h2>
          <p class="section-description">{{
            $t(`${base}.lasted.description`)
          }}</p>
        </div>

        <LazyLoading
          :key="`latest`"
          :loading="products.length === 0"
          :loaded="lazy.latest"
          @loaded="(e) => (lazy.latest = e)"
        >
          <Swiper
            v-if="lazy.latest"
            ref="swiperExperiences"
            :options="swiperOption"
          >
            <SwiperSlide v-for="(post, i) in products" :key="i">
              <LadCard
                :item="post"
                :base="base"
                :to="{
                  name: 'product-permalink',
                  params: {
                    type: $t(`router.product_permalink.type.${post.type}`),
                    permalink: post.permalink,
                  },
                }"
              />
            </SwiperSlide>

            <div slot="button-prev" class="swiper-button-prev"></div>
            <div slot="button-next" class="swiper-button-next"></div>
          </Swiper>

          <template slot="css-skeleton">
            <LadExperienceSwiperLoader />
          </template>
        </LazyLoading>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'
import LadExperiences from './components/common'

export default {
  name: 'LadExperiencesLatest',
  extends: LadExperiences,
  mixins: [CacheComponentMixin],
  data() {
    return {
      lazy: { latest: false },
    }
  },
  watch: {
    'lazy.latest': {
      immediate: true,
      handler(newValue, oldValue) {
        if (process.server) return

        if (newValue) {
          this.getData()
        }
      },
    },
  },
  methods: {
    async getData() {
      try {
        const { data, status } = await this.$axios.get(
          `/api/v1.5/experiences/list/latest`
        )

        if (status === 'success') {
          this.setData(data)
        }
      } catch (error) {}
    },
  },
}
</script>
